import { File, FileEntity, WorkflowEntity } from 'app/types/documentTypes';

export enum GoogleRessourceRole {
  READER = 'reader',
  WRITER = 'writer',
  COMMENTER = 'commenter',
  ORGANIZER = 'organizer',
  FILE_ORGANIZER = 'fileOrganizer',
  OWNER = 'owner',
}

export class FileResponse {
  /** The file. */
  file: File;

  user_permissions?: GoogleRessourceRole;

  constructor() {
    this.file = new File();
  }
}

export interface FilesResponse {
  /** The files. */
  files: File[];
}

export interface WorkflowResponse {
  workflow: WorkflowEntity;
}

export interface WorkflowsResponse {
  /** The workflows. */
  workflows: WorkflowEntity[];
}

export enum LabelType {
  VERIFIED = 'verified',
  TAGGED = 'tagged',
  POPULAR = 'popular',
}
