import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import type { FileEntity, PrimeFileNode } from 'app/types/documentTypes';
import { File } from 'app/types/documentTypes';
import { People } from 'app/types/peopleTypes';
import { TrinityService } from 'app/services/trinity/trinity.service';
import {
  Contribution,
  ContributionsResponse,
  DriveEntityResponse,
  FileFlagType,
  GetDriveFilesResponse,
  GetUserAuthorizedDrivesResponse,
  GetUserDrivesOptions,
  GetUserDrivesResponse,
  SearchFilesOptions,
} from 'app/types/gdrives.types';
import { EventManager } from 'app/services/events/events.service';

@Injectable({
  providedIn: 'root',
})
export class GdrivesService {
  driveDataSingleton: Array<PrimeFileNode> = new Array<PrimeFileNode>();
  driveElementSingleton?: PrimeFileNode;
  allUserFiles: Array<File> = new Array<File>();
  tableSelectedFile: Array<PrimeFileNode> = new Array<PrimeFileNode>();
  searchResults: Array<FileEntity | People | Contribution> =
    new Array<FileEntity>();

  private readonly endpoint: string = '/drive';

  constructor(private trinityService: TrinityService) {}

  getUserDrives(
    options: GetUserDrivesOptions,
  ): Observable<GetUserDrivesResponse> {
    const params: { [key: string]: string } = {
      limit: options.limit.toString(),
    };

    if (options.next_page_token)
      params['next_page_token'] = options.next_page_token;

    if (options.include_domain_wide)
      params['include_domain_wide'] = options.include_domain_wide.toString();

    return this.trinityService.get(`${this.endpoint}/drives`, {
      params,
      authorized: true,
    });
  }

  getUserAuthorizedDrives(
    options: GetUserDrivesOptions,
  ): Observable<GetUserAuthorizedDrivesResponse> {
    const params: { [key: string]: string } = {
      limit: options.limit.toString(),
    };

    if (options.next_page_token)
      params['next_page_token'] = options.next_page_token;

    return this.trinityService.get(`${this.endpoint}/drive/authorized`, {
      params,
      authorized: true,
    });
  }

  getCurrentScans(): Observable<DriveEntityResponse> {
    return this.trinityService.get<DriveEntityResponse>(
      `${this.endpoint}/scan`,
      {
        authorized: true,
      },
    );
  }

  getFolderChildren(
    folderId: string,
    nextToken?: string | null,
  ): Observable<GetDriveFilesResponse> {
    const params: { [key: string]: string } = {
      search: `'${folderId}' in parents and mimeType='application/vnd.google-apps.folder' and trashed=false`,
    };

    if (nextToken) params['next_page_token'] = nextToken;

    return this.trinityService.get<GetDriveFilesResponse>(
      `${this.endpoint}/files`,
      {
        params,
        authorized: true,
      },
    );
  }

  searchFiles(options: SearchFilesOptions): Observable<GetDriveFilesResponse> {
    const params: { [key: string]: string } = {};

    params['search'] = options.search;
    if (options.limit) params['limit'] = options.limit.toString();
    if (options.driveid) params['drive_id'] = options.driveid;

    if (options.next_page_token)
      params['next_page_token'] = options.next_page_token;

    return this.trinityService.get<GetDriveFilesResponse>(
      `${this.endpoint}/files`,
      {
        params,
        authorized: true,
      },
    );
  }

  getDriveVerifiedFiles(
    type: FileFlagType = FileFlagType.VERIFIED,
    limit: number = 50,
    nextPageToken?: string,
  ): Observable<GetDriveFilesResponse> {
    const params: { [key: string]: string } = {
      type: type,
      limit: limit.toString(),
    };

    if (nextPageToken) params['next_page_token'] = nextPageToken;

    return this.trinityService.get<GetDriveFilesResponse>(
      `${this.endpoint}/verified`,
      {
        params,
        authorized: true,
      },
    );
  }

  setDriveElementSingleton(driveElement: PrimeFileNode): void {
    this.driveElementSingleton = driveElement;
  }

  getDriveElementSingleton(): PrimeFileNode | undefined {
    return this.driveElementSingleton;
  }

  setDriveSingleton(singleton: Array<PrimeFileNode>): void {
    this.driveDataSingleton = singleton;
  }

  getDriveSingleton(): Array<PrimeFileNode> {
    return this.driveDataSingleton;
  }

  setUserAllFiles(files: Array<File>): void {
    this.allUserFiles = files;
  }

  getUserAllFiles(): Array<File> {
    return this.allUserFiles;
  }

  setTableSelectedFile(files: Array<PrimeFileNode>): void {
    this.tableSelectedFile = files;
    EventManager.emit('tableSelectedFiles');
  }

  getTableSelectedFile(): Array<PrimeFileNode> {
    return this.tableSelectedFile;
  }

  setSearchResults(results: Array<FileEntity | People | Contribution>): void {
    this.searchResults = results;
  }

  getSearchResults(): Array<FileEntity | People | Contribution> {
    return this.searchResults;
  }

  getContributions(
    search: string,
    next_page_token?: string,
  ): Observable<ContributionsResponse> {
    const params: { [key: string]: string } = {
      limit: '50',
      search,
    };

    if (next_page_token) params['next_page_token'] = next_page_token;

    return this.trinityService.get<ContributionsResponse>(
      `${this.endpoint}/contributions`,
      {
        params,
        authorized: true,
      },
    );
  }
}
