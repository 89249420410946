import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth/auth.service';
import { Router } from '@angular/router';
import { Role } from 'app/types/userTypes';
import { ReplaySubject, takeUntil } from 'rxjs';
import {
  GetAuthorizeStateOptions,
  VerifyRejectionReason,
} from 'app/types/auth.types';
import { LoginOptions } from './login.types';
import { LocalStorageService } from 'app/services/local-storage/local-storage.service';

enum LoginState {
  UNKNOWN = 'unknown',
  UNAUTHENTICATED = 'unauthenticated',
  VALIDATING = 'validating',
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, OnDestroy {
  readonly defaultRedirect = 'verified';
  readonly isLoggedFromSidePanelPopup = 'isLoggedFromSidePanelPopup';
  readonly isSidePanelAuthPopupValue = 'isSidePanelAuthPopup';
  readonly sidePanelAuthPopupParam = 'sidepanel_auth_popup';
  protected waitingForSidePanelPopup = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  loginState: LoginState = LoginState.UNKNOWN;

  constructor(
    private authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  onNavigation() {
    /** Loading screen. */
    this.loginState = LoginState.VALIDATING;

    // Check if the access token is already stored
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get('redirect');
    const error = urlParams.get('error');

    const code = urlParams.get('code');
    let state: GetAuthorizeStateOptions | undefined;
    const stateB64str = urlParams.get('state');

    if (stateB64str) {
      state = JSON.parse(atob(stateB64str));
    }

    /** The login without consent failed, fallback to consent screen. */
    if (error === 'interaction_required') {
      /** Login with force consent because Google said so. */
      this.login({ forceConsent: true, state: state });
      return;
    }

    /** If the uri contains an authorize flow code. */
    if (code) {
      /** Retrieve an access token pair from an authorization code. */
      this.authService
        .getAccessToken(code)
        .then(() => {
          if (state?.redirectPath) {
            this.redirect(state.redirectPath);
            return;
          }
          this.redirect(redirect ?? this.defaultRedirect);
          return;
        })
        .catch((err) => {
          if (
            err?.reason === VerifyRejectionReason.GOOGLE_REFRESH_TOKEN_EXPIRED
          ) {
            /** Login with force consent to retrieve a new Google refresh token. */
            this.login({ forceConsent: true, state: state });
            return;
          }
          this.loginState = LoginState.UNAUTHENTICATED;
          this.authService.logout();
          console.error(err);
          return;
        });
      return;
    }

    /** Login inside pop-up for side panel **/
    if (this.isSidePanelAuthPopup()) {
      this.localStorageService.setItem(this.isSidePanelAuthPopupValue, 'true');
      this.login();
      return;
    }

    /** Set state to validating for loading icon. */
    this.loginState = LoginState.VALIDATING;

    /** If there is no code, see if we are already authenticated. */
    this.authService
      .isUserAuthenticated()
      .then(() => {
        /** User is authenticated, redirect to the specified page. */
        this.redirect(redirect ?? this.defaultRedirect);
      })
      .catch((e) => {
        /** Set state to validating for loading icon. */
        this.loginState = LoginState.VALIDATING;

        /** If the backend refresh token is not present or expired. */
        if (e?.reason === VerifyRejectionReason.GOOGLE_REFRESH_TOKEN_EXPIRED) {
          /** Login with force consent to retrieve a new Google refresh token. */
          this.login({ forceConsent: true, state: state });
          return;
        }

        /** If there is no credentials in cache. */
        if (e?.reason === VerifyRejectionReason.LOCAL_CREDENTIALS_EMPTY) {
          this.loginState = LoginState.UNAUTHENTICATED;
          return;
        }

        /** User seems to have credentials, try to refresh its token. */
        this.authService
          .refreshAccessToken()
          .then(() => {
            /** Token refresh, simply redirect him to its last page. */
            this.redirect(redirect ?? this.defaultRedirect);
          })
          .catch((e) => {
            /** Refresh failed. */
            if (
              e?.reason === VerifyRejectionReason.GOOGLE_REFRESH_TOKEN_EXPIRED
            ) {
              /** Login with force consent to retrieve a new Google refresh token. */
              this.login({ forceConsent: true, state: state });
            } else {
              /** Login normally. */
              this.login({ forceConsent: false, state: state });
            }
          });
      });
  }

  isSidePanelAuthPopup(): boolean {
    const url = new URL(window.location.href);
    return (
      url.pathname === '/login' &&
      url.searchParams.get(this.sidePanelAuthPopupParam) === 'true'
    );
  }

  isSidePanelLogin(): boolean {
    const url = new URL(window.location.href);
    return (
      url.pathname === '/login' &&
      url.searchParams.get('redirect') === 'side-panel' &&
      url.searchParams.get(this.sidePanelAuthPopupParam) !== 'true' &&
      !url.searchParams.get('code') && // we do this to differentiate from side panel popup that received the oauth callback
      !url.searchParams.get('error')
    );
  }

  ngOnInit() {
    this.onNavigation();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  redirect(redirect_uri: string): void {
    /** Close popup for side panel instead of redirect **/
    if (this.localStorageService.getItem(this.isSidePanelAuthPopupValue)) {
      this.localStorageService.deleteItem(this.isSidePanelAuthPopupValue);
      this.localStorageService.setItem(this.isLoggedFromSidePanelPopup, 'true');
      window.close();
    }
    this.router.navigate([`/${redirect_uri}`]);
  }

  async handleAuthInSidePanel() {
    const url = new URL(window.location.href);
    url.searchParams.append(this.sidePanelAuthPopupParam, 'true');
    window.open(url.href, '_blank', 'height=800,width=600');
    // waiting for popup to validate oauth
    /** Set state to validating for loading icon. */
    this.loginState = LoginState.VALIDATING;
    this.waitingForSidePanelPopup = true;

    while (!localStorage.getItem(this.isLoggedFromSidePanelPopup)) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    /** Set state to validating for loading icon. */
    this.waitingForSidePanelPopup = false;
    this.localStorageService.deleteItem(this.isLoggedFromSidePanelPopup);
    this.onNavigation();
  }

  async login(options: LoginOptions = {}) {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUri = new URL(this.authService.redirectUri);

    /** Login inside side panel **/
    if (this.isSidePanelLogin()) {
      // Open a new window to login
      this.handleAuthInSidePanel();
      return;
    }

    const redirectParam = urlParams.get('redirect');
    if (redirectParam) {
      redirectUri.searchParams.append('redirect', redirectParam);
    }

    this.authService.logout();

    this.authService
      .getAuthorizeUrl({
        forceConsent: options.forceConsent,
        redirectUri: redirectUri.href,
        role: Role.CLIENT,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          window.location.href = res.authorize_url;
        },
      });
  }
}
