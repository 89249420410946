@if (mode === 'documents') {
  <p-table
    [value]="documents"
    [tableStyle]="{ 'min-width': '50rem' }"
    [(selection)]="selectedFiles"
    (onHeaderCheckboxToggle)="sendSelectedFiles()"
    (onRowSelect)="sendSelectedFiles()"
    (onRowUnselect)="sendSelectedFiles()"
    sortField="last_modified"
    [sortOrder]="-1"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        @if (canSelect) {
          <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
        }
        <th>Name</th>
        <!-- TO DISPLAY STATUS -->
        @if (showApprovers) {
          <th></th>
        }
        <th>Tags</th>
        @if (showApprovers) {
          <th>Approvers</th>
        } @else {
          <th>Contributors</th>
        }
        <th>Labels</th>
        @if (showLastAction) {
          <th>Last action</th>
        } @else {
          <th pSortableColumn="last_modified">
            Last update<p-sortIcon field="last_modified" />
          </th>
        }
        <th pSortableColumn="averageRating">
          Ratings <p-sortIcon field="averageRating" />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-file let-columns="columns">
      <tr class="table-clickable-row {{ file?.selectedRow }}">
        @if (canSelect) {
          <td>
            <p-tableCheckbox [value]="file" />
          </td>
        }
        <!-- DO NOT REMOVE 'PRESENCEDOC_APP_EXTENSION' AND 'id="{{file.id}}' THEY ARE USED FOR THE EXTENSION -->
        <td
          class="file-name PRESENCEDOC_APP_EXTENSION"
          pTooltip="{{ file.name }}"
          id="{{ file.id }}"
          [attr.docurl]="file?.webview_link"
        >
          <img src="{{ file.icon_link }}" alt="" class="file-icon" />
          <a href="{{ file?.webview_link }}" target="_blank"
            ><span>{{ file.name }}</span></a
          >
        </td>
        @if (showApprovers) {
          <td>
            @if (workflows.get(file.id)?.state === 'waiting') {
              <p-tag
                class="pending-tag waiting"
                icon="pi pi-clock"
                value="Waiting"
              ></p-tag>
            }
            @if (workflows.get(file.id)?.state === 'rejected') {
              <p-tag
                class="pending-tag rejected"
                icon="pi pi-times"
                value="Rejected"
              ></p-tag>
            }
            @if (workflows.get(file.id)?.state === 'validated') {
              <p-tag
                class="pending-tag approved"
                icon="pi pi-check"
                value="Approved"
              ></p-tag>
            }
          </td>
        }
        <td>
          <div class="ellipsis" pTooltip="{{ getTooltipTags(file) }}">
            <span>{{ getTooltipTags(file) }}</span>
          </div>
        </td>
        @if (showApprovers) {
          <td>
            <p-avatarGroup>
              @if (workflows.get(file.id) !== undefined) {
                @for (
                  approver of workflows.get(file.id)?.related_users;
                  track approver
                ) {
                  <p-avatar
                    pTooltip="{{
                      getWorkflowContributors($any(approver).user)
                    }}"
                    image="{{ getAvatarImage($any(approver).user.people) }}"
                    size="normal"
                    shape="circle"
                  />
                }
              }
            </p-avatarGroup>
          </td>
        } @else {
          <td>
            @if (file?.datamart?.contributors?.length !== 0) {
              <p-avatarGroup>
                <p-avatar
                  pTooltip="{{
                    getContributorTooltip(file.datamart?.created_by)
                  }}"
                  image="{{ getAvatarImage(file.datamart?.created_by) }}"
                  size="normal"
                  shape="circle"
                />
                @for (
                  contrib of file?.datamart?.contributors;
                  track contrib;
                  let index = $index
                ) {
                  @if (index < maximalAvatar) {
                    <p-avatar
                      pTooltip="{{ getContributorTooltip(contrib) }}"
                      image="{{ getAvatarImage(contrib) }}"
                      size="normal"
                      shape="circle"
                    />
                  }
                }
                @if (file?.datamart?.contributors?.length > maximalAvatar) {
                  <p-avatar
                    pTooltip="{{
                      getContributorsTooltip(file?.datamart?.contributors)
                    }}"
                    label="+{{
                      file?.datamart?.contributors?.length - maximalAvatar
                    }}"
                    styleClass="mr-2"
                    shape="circle"
                  />
                }
              </p-avatarGroup>
            } @else {
              <p-avatar
                pTooltip="{{
                  getContributorTooltip(file.datamart?.created_by)
                }}"
                image="{{ getAvatarImage(file.datamart?.created_by) }}"
                size="normal"
                shape="circle"
              />
            }
          </td>
        }
        <td>
          <div class="label-cell">
            @for (label of file.labels; track label) {
              @if (label.type.toLowerCase() === 'verified') {
                <p-tag value="{{ label.type }}" class="success"></p-tag>
              }
              @if (label.type.toLowerCase() === 'flagged') {
                <p-tag value="{{ label.type }}" class="warning"></p-tag>
              }
            }
          </div>
        </td>
        @if (showLastAction) {
          <td>
            {{ $any(workflows.get(file.id))?.updated_at | date: 'dd/MM/yyyy' }}
          </td>
        } @else {
          <td>{{ file.last_modified | date: 'dd/MM/yyyy' }}</td>
        }
        @if (!file?.ratings?.length) {
          <td>
            <i class="pi pi-star-fill"></i>
            <span>No ratings</span>
          </td>
        } @else {
          <td>
            <i class="pi pi-star-fill golden-star"></i>
            <span
              >{{ getRatingAverage(file).toFixed(1) }}/{{
                maximalMarkPossible
              }}</span
            >
            <span>&nbsp;({{ file?.ratings?.length }})</span>
          </td>
        }
      </tr>
    </ng-template>
  </p-table>
} @else {
  <p-table [value]="peoples" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header" let-columns>
      <tr>
        @if (canSelect) {
          <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
        }
        <th><i class="pi pi-user"></i></th>
        <th pSortableColumn="name">Name <p-sortIcon field="name" /></th>
        <th>Email</th>
        <th>Contributions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-people let-columns="columns">
      <tr class="table-clickable-row">
        @if (canSelect) {
          <td>
            <p-tableCheckbox [value]="people" />
          </td>
        }
        <td>
          <p-avatar
            image="{{ people.people.photo_uri ?? default_avatar_uri }}"
            styleClass="mr-2"
            size="normal"
            shape="circle"
          ></p-avatar>
        </td>
        <td class="file-name">{{ people.people.fullname ?? '-'}}</td>
        <td>{{ people.people.email ?? '-' }}</td>
        <td>{{ people.count }}</td>
      </tr>
    </ng-template>
  </p-table>
}
