import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrinityService } from 'app/services/trinity/trinity.service';
import { VersioningResponse } from 'app/types/versioning.types';

@Injectable({
  providedIn: 'root',
})
export class VersioningService {
  private readonly endpoint: string = '/verified-history';
  constructor(private trinityService: TrinityService) {}

  getHistory(fileId: string): Observable<VersioningResponse> {
    const res = this.trinityService.get<VersioningResponse>(
      `${this.endpoint}/${fileId}`,
      {
        authorized: true,
      },
    );

    return res;
  }

  createDraft(fileId: string): Observable<VersioningResponse> {
    const res = this.trinityService.post<VersioningResponse>(
      `${this.endpoint}/draft/${fileId}`,
      {
        authorized: true,
      },
    );

    return res;
  }

  attachFileToHistory(
    historyId: number,
    fileId: string,
  ): Observable<VersioningResponse> {
    const res = this.trinityService.post<VersioningResponse>(
      `${this.endpoint}/attach`,
      {
        body: {
          historyId: historyId,
          fileId: fileId,
        },
        authorized: true,
      },
    );

    return res;
  }
}
