<app-loading-component [isLoading]="sidePanel.status === 'LOADING'">
  @if (sidePanel.status === 'UNKNOWN') {
    <!-- NO DOCUMENT SELECTED -->
    <div class="center-load">
      <p>No document selected</p>
    </div>
  } @else if (sidePanel.status === 'MY_DRIVE') {
    <div class="my-drive">
      <img src="assets/icons/Overlayer-denied-icon.svg" class="overlayer-icon" alt="denied-icon" />
      <h1 class="my-drive-title">
        Overlayer's features are not usable on "My drive"
      </h1>
      <span class="my-drive-paragraph">
        Move this document from "My Drive" to a shared drive to use Overlayer's features on it.
      </span>
    </div>
  } @else if (sidePanel.status === 'FILE_NOT_WATCHED') {
    <div class="not-watched">
      <div class="not-watched-top">
        <img src="assets/icons/Overlayer-warning-icon.svg" class="overlayer-icon" alt="warning icon" />
        <h1 class="my-drive-title">
          Overlayer's features are not installed on this drive
        </h1>
        <span class="my-drive-paragraph">
          Install Overlayer to use its features on the shared drive's files.
        </span>
      </div>
      <div class=" not-watched-bottom">
        <p-button
          label="Add Overlayer to the shared drive"
        />
        <span class="my-drive-paragraph-smaller">
          <i class="pi pi-info-circle"></i> A technical user will be added as a contributor to the file's shared drive
        </span>
      </div>
    </div>
  }
  @else {
    <div class="side-panel {{ lockedFileClass }}" >
      <!-- HEADER -->
      <div class="title-container">
        @if (sidePanel.fileLocked) {
          <h1
            pTooltip="This doc is locked to maintain its integrity: edits are disabled. 
            Only document editor(s) can unlock it."
          >
            <i class="pi pi-lock"></i>
            {{ sidePanel.documentName }}
          </h1>
        } @else {
          <h1>{{ sidePanel.documentName }}</h1>
        }
        <div class="title-rating">
          @if (sidePanel.documentRating > 0) {
            <i class="pi pi-star-fill yellow"></i>
            <span>{{ sidePanel.documentRating }}/5</span>
          }
        </div>
      </div>

      <!-- LABELS -->
      <div class="labels-container">
        @if (sidePanel.documentIsDraft) {
          <p-tag
            class="draft"
            icon="pi pi-file-edit"
            value="Version draft"
          ></p-tag>
        }
        @for (label of sidePanel.documentLabels; track label) {
          @if (label?.type === LABEL_TYPE.FLAGGED) {
            <p-tag
              (click)="onTagClick($event, op)"
              class="{{ label?.type }}"
              icon="pi pi-verified"
              value="{{ label?.type }}"
            ></p-tag>
            <p-overlayPanel #op class="custom-overlay-panel">
              <div class="title">Flag reason</div>
              <div>
                Lastly flagged
                {{ label?.by?.email ? 'by: ' + label?.by?.email : '' }} ({{
                  formatDate(label?.by?.created_at)
                }}) because "{{ label?.reason }}"
              </div>
            </p-overlayPanel>
          } @else if (label?.type === LABEL_TYPE.VERIFIED_HAS_BEEN_EDITED) {
            <p-tag class="verified-expiring">
              <div class="verified-expiring-content">
                <img
                  alt="breaking-shield"
                  src="assets/icons/shield-off.svg"
                  class="breaking-shield"
                />
                <span>Verified</span>
              </div>
            </p-tag>
          } @else if (label?.type.toLowerCase() === LABEL_TYPE.TO_UPDATE_OUTDATED) {
            <p-tag
              class="verified-expiring"
              icon="pi pi-verified"
              value="Verified (Outdated)"
            ></p-tag>
            <p-tag
              class="verified-outdated"
              icon="pi pi-exclamation-triangle"
              value="Outdated version"
            ></p-tag>
          } 
          @else if (label?.type.toLowerCase() === LABEL_TYPE.VERIFIED) {
              <p-tag
                class="{{ label?.type }}"
                icon="pi pi-verified"
                value="{{ label?.type }}"
              ></p-tag>
          }
        }
      </div>

      <!-- SPACING -->
      <div class="spacing-separator"></div>

      <!-- CREATORS / CONTRIBUTORS -->
      <div class="contributors-container">
        <span class="span-header">Creator & Contributor(s)</span>
        <div class="contributions-container">
          <p-tag
            class="grey-tag"
            icon="pi pi-user"
            value="{{ sidePanel.documentCreator }}"
          />
          @for (
            contributor of sidePanel.documentContributors;
            track contributor
          ) {
            <p-tag
              class="grey-tag"
              icon="pi pi-users"
              value="{{ contributor }}"
            />
          }
        </div>
      </div>

      <!-- DOCUMENT DESCRIPTION  -->
      @if (sidePanel.documentDescription) {
        <div class="description-container">
          <span class="span-header">Description</span>
          <div class="description">
            <span>{{ sidePanel.documentDescription }}</span>
          </div>
        </div>
      }

      <!-- TAGS -->
      <div class="tags-container">
        <span class="span-header">Tags</span>
        <div class="tags-list">
          <!-- LANGUAGE TAG -->
          @if (sidePanel.documentLanguage) {
            <p-tag
              class="grey-tag"
              icon="pi pi-language"
              value="{{ sidePanel.documentLanguage }}"
            />
          }
          <!-- CONFIDENTIALITY TAG -->
          @if (sidePanel.documentConfidentiality) {
            <p-tag
              class="grey-tag"
              icon="pi pi-lock"
              value="{{ sidePanel.documentConfidentiality }}"
            />
          }
          <!-- DOCUMENT TYPE TAG -->
          @if (sidePanel.documentType) {
            <p-tag
              class="grey-tag"
              icon="pi pi-briefcase"
              value="{{ sidePanel.documentType }}"
            />
          }
          <!-- CUSTOM TAGS -->
          @for (tag of sidePanel.documentTags; track tag) {
            @if (tag.source === 'suggested') {
              <p-tag
                class="grey-tag"
                icon="pi pi-sparkles"
                value="{{ tag.label }}"
              />
            } @else {
              <p-tag
                class="grey-tag"
                icon="pi pi-tag"
                value="{{ tag.label }}"
              />
            }
          }

          <!-- BUTTON TO UPDATE PROPERTIES -->
          @if (!sidePanel.userIsReaderOrCommentator && !sidePanel.fileLocked) {
            <div
              class="text-icon-button"
              (mousedown)="toggleDialog('properties')"
            >
              <i class="pi pi-plus-circle"></i>
              <span>Edit properties</span>
            </div>
          }
        </div>
      </div>

      <!-- DOCUMENT RATING  -->
      @if (!sidePanel.userCurrentRating) {
        <div class="ratings-container">
          <span class="span-header">Ratings</span>
          <div class="add-rating-container">
            <i class="pi pi-star"></i>
            <span>You did not leave a rating yet.</span>
            @if (!sidePanel.fileLocked) {
              <div
                class="text-icon-button"
                (mousedown)="toggleDialog('rating')"
              >
                <i class="pi pi-plus-circle"></i>
                <span>Add a rating</span>
              </div>
            }
          </div>
        </div>
      }

      <!-- WORKFLOW ACTIVITY -->
      <div class="activity-container">
        <span class="span-header">Activity</span>
        <div class="activity-status">
          <i class="pi pi-verified"></i>
          <span>Verification - </span>
          @if (sidePanel.workflowState === WORKFLOW_TYPE.PENDING) {
            <p-tag
              class="waiting-workflow"
              icon="pi pi-clock"
              value="Pending approval"
              severity="warning"
            />
          } @else if (sidePanel.workflowState === WORKFLOW_TYPE.REJECTED) {
            <p-tag
              class="rejected-workflow"
              icon="pi pi-times"
              value="Rejected"
              severity="danger"
            />
          } @else if (sidePanel.verifiedHasBeenModified) {
            <span>To renew</span>
          } @else if (sidePanel.workflowState === WORKFLOW_TYPE.APPROVED) {
            <p-tag
              class="validated-workflow"
              icon="pi pi-check"
              value="Approved"
              severity="success"
            />
          } @else {
            <span>No workflow started</span>
          }
        </div>
        @if (!sidePanel.fileLocked) {
          @if (
            sidePanel.userRelatedToWorkflow ||
            sidePanel.workflowState === WORKFLOW_TYPE.NONE ||
            sidePanel.workflowState === WORKFLOW_TYPE.REJECTED ||
            sidePanel.verifiedHasBeenModified
          ) {
            <div class="activity-actions">
              @if (
                sidePanel.workflowState === WORKFLOW_TYPE.NONE ||
                sidePanel.workflowState === WORKFLOW_TYPE.REJECTED ||
                sidePanel.verifiedHasBeenModified
              ) {
                <p-button
                  class="activity-button"
                  label="Start verification"
                  size="small"
                  (mousedown)="handleWorkflowAction('verify')"
                />
              }
              @if (sidePanel.workflowState === WORKFLOW_TYPE.PENDING) {
                <p-button
                  class="activity-button"
                  label="Reject"
                  [outlined]="true"
                  size="small"
                  (mousedown)="handleWorkflowAction('reject')"
                />
                <p-button
                  class="activity-button"
                  label="Approve"
                  size="small"
                  (mousedown)="handleWorkflowAction('approve')"
                />
              }
            </div>
          }
        }
      </div>

      <!-- VERSIONNING -->
      @if ((sidePanel.documentIsVerified || sidePanel.documentIsDraft) && sidePanel.documentHasVersioning) {
        <div class="versioning-container">
          <span class="span-header">Version history</span>
          <div class="add-versioning-container">
            @if (
              sidePanel.documentHistory?.history?.latest_version?.file?.id ===
                sidePanel.documentId || sidePanel.documentHistoryHasADraft
            ) {
              @if (sidePanel.documentHistory?.history?.versions?.length === 1) {
                <div class="versioning-no-newer-file">
                  <i class="pi pi-verified"></i>
                  <span>There's no newer version of that file.</span>
                </div>
              }
              @if (sidePanel.documentHistoryHasADraft) {
                <div class="warning-draft" (mousedown)="openFileInGDrive()">
                  <i class="pi pi-exclamation-triangle"></i>
                  <span>There's an ongoing draft</span>
                  <i class="pi pi-external-link"></i>
                </div>
              } @else {
                @if (
                  sidePanel.documentHistory?.history?.versions?.length !== 1
                ) {
                  @for (
                    version of sidePanel.documentHistory?.history?.versions;
                    track version
                  ) {
                    <div class="history-row">
                      <div class="history-gap">
                        <i class="pi pi-file-check"></i>
                        <span
                          class="ellipsis-name text-link"
                          (mousedown)="openFileInGDrive(version?.file?.id)"
                          >{{ version?.file?.datamart?.name }}</span
                        >
                        @if (version.file.id === sidePanel.documentId) {
                          <p-tag class="new-version-tag" value="Current" />
                        } @else {
                          <p-tag
                            class="verified"
                            value="V{{ version?.version }}"
                          />
                        }
                      </div>
                      <span>{{ version?.verified_at | date }}</span>
                    </div>
                  }
                }
              }
            } @else {
              @if (sidePanel.documentIsVerified) {
                <div
                  class="warning-draft"
                  (mousedown)="
                    openFileInGDrive(
                      sidePanel.documentHistory?.history?.latest_version?.file
                        ?.id
                    )
                  "
                >
                  <i class="pi pi-exclamation-triangle"></i>
                  <span>There's a newer version of this file</span>
                  <i class="pi pi-external-link"></i>
                </div>
              } @else {
                @if (sidePanel.documentIsDraft) {
                  <div class="history-row">
                    <div class="history-gap">
                      <i class="pi pi-file-check"></i>
                      <span
                        class="ellipsis-name text-link"
                        (mousedown)="
                          openFileInGDrive(
                            sidePanel.documentHistory?.history?.latest_version
                              ?.file?.id
                          )
                        "
                        >{{
                          sidePanel.documentHistory?.history?.latest_version
                            ?.file?.datamart?.name
                        }}</span
                      >
                      <p-tag class="verified" value="Verified" />
                    </div>
                    <span>{{
                      sidePanel.documentHistory?.history?.latest_version
                        ?.verified_at | date
                    }}</span>
                  </div>
                }
              }
            }

            @if (
              sidePanel.documentHistory?.history?.latest_version?.file?.id ===
                sidePanel.documentId || sidePanel.documentHistoryHasADraft
            ) {
              <div
                class="text-icon-button"
                (mousedown)="toggleDialog('versioning')"
              >
                <i class="pi pi-plus-circle"></i>
                <span>Add a version</span>
              </div>
            }
          </div>
        </div>
      }
    </div>

    <!-- SIDEPANEL FOOTER-->
    <div class="side-panel-footer">
      <!-- LIKE BUTTON -->
      @if (sidePanel.likedByMe) {
        <p-button
          class="flex-like"
          icon="pi pi-thumbs-up"
          label="{{ sidePanel.likesCount }}"
          severity="secondary"
          (mousedown)="dislikeDocument()"
        />
      } @else if (!sidePanel.likesCount) {
        <p-button
          class="flex-like"
          icon="pi pi-thumbs-up"
          label="Like"
          [outlined]="true"
          severity="secondary"
          (mousedown)="likeDocument()"
        />
      } @else {
        <p-button
          class="flex-like"
          icon="pi pi-thumbs-up"
          label="{{ sidePanel.likesCount }}"
          [outlined]="true"
          severity="secondary"
          (mousedown)="likeDocument()"
        />
      }

      <!-- SPLIT BUTTON -->
      <p-splitButton
        label="{{
          sidePanel.userCurrentRating === 0
            ? 'Add a rating'
            : 'Update my rating'
        }}"
        [model]="sidePanel.splitButtonItems"
        outlined
        (onClick)="toggleDialog('rating')"
        severity="secondary"
      />
    </div>
  }
</app-loading-component>

<!-- DIALOGS -->
<!-- DESCRIPTION DIALOG -->
<p-dialog
  header="Edit description"
  [modal]="true"
  [(visible)]="sidePanel.openDialogs.description"
  [draggable]="false"
  position="bottom"
  [style]="{ width: '100%' }"
>
  <textarea
    rows="5"
    pInputTextarea
    [(ngModel)]="sidePanel.selectedDocumentDescription"
    class="description-textarea"
  >
  </textarea>
  <div class="description-actions">
    <p-button
      label="Cancel"
      severity="secondary"
      (mousedown)="toggleDialog('description')"
    />
    <p-button
      label="Save"
      [disabled]="
        sidePanel.selectedDocumentDescription === sidePanel.documentDescription
      "
      (mousedown)="updateDocumentDescription()"
    />
  </div>
</p-dialog>

<!-- RATING DIALOG -->
<p-dialog
  header="Select your rating"
  [modal]="true"
  [(visible)]="sidePanel.openDialogs.rating"
  [draggable]="false"
  position="bottom"
  [style]="{ width: '100%' }"
>
  <div class="apply-rating">
    <span>How would you rate this document?</span>
    <p-rating
      stars="5"
      [(ngModel)]="sidePanel.selectedRating"
      [cancel]="false"
    ></p-rating>
    <p-button label="Apply rating" (mousedown)="updateDocumentRating()" />
  </div>
</p-dialog>

<!-- PROPERTIES DIALOG -->
<p-dialog
  header="Edit properties"
  [modal]="true"
  [(visible)]="sidePanel.openDialogs.properties"
  [draggable]="false"
  position="bottom"
  [style]="{ width: '100%' }"
>
  <div class="apply-properties">
    <p-autoComplete
      [(ngModel)]="sidePanel.selectedDocumentTags"
      [suggestions]="sidePanel.suggestedTags"
      optionLabel="label"
      [multiple]="true"
      (completeMethod)="searchTags($event)"
      [virtualScroll]="true"
      [virtualScrollItemSize]="30"
      placeholder="Select tags"
    >
      <ng-template let-tag pTemplate="item">
        @if (tag.id !== -1) {
          <div class="tag-selector">
            <i class="pi pi-tags"></i>
            <span>{{ tag.label }}</span>
          </div>
        } @else {
          <div class="tag-selector blue">
            <i class="pi pi-plus-circle"></i>
            <span>{{ tag.label }}</span>
          </div>
        }
      </ng-template>
    </p-autoComplete>
    <p-dropdown
      [options]="sidePanel.documentConfidentialities"
      [(ngModel)]="sidePanel.selectedDocumentConfidentiality"
      optionLabel="name"
      placeholder="Confidentiality"
      class="dropdown-width"
    />
    <p-dropdown
      [options]="sidePanel.documentTypes"
      [(ngModel)]="sidePanel.selectedDocumentType"
      optionLabel="name"
      placeholder="Document type"
      class="dropdown-width"
    />
    <p-dropdown
      [options]="sidePanel.languages"
      [(ngModel)]="sidePanel.selectedLanguage"
      optionLabel="name"
      placeholder="Language"
      class="dropdown-width"
    />
    <diV class="properties-buttons">
      <p-button
        label="Cancel"
        severity="secondary"
        (mousedown)="toggleDialog('properties')"
      />
      <p-button label="Save changes" (mousedown)="updateDocumentProperties()" />
    </diV>
  </div>
</p-dialog>

<!-- WORKFLOW APPROVAL DIALOG -->
<p-dialog
  header="Last step - Confidentiality & Expiration date"
  [modal]="true"
  [draggable]="false"
  [(visible)]="sidePanel.openDialogs.workflow"
  position="bottom"
  [style]="{ width: '100%' }"
  class="dropdown-scroll"
>
  <ng-template pTemplate="header">
    <div class="workflow-header">
      <span>Last step - Confidentiality & Expiration date</span>
    </div>
  </ng-template>
  <app-loading-component
    [isLoading]="sidePanel.childrenStatus['workflow'] === 'LOADING'"
  >
    <div class="apply-rating">
      <p-dropdown
        [options]="sidePanel.documentConfidentialities"
        [(ngModel)]="sidePanel.selectedApprovalConfidentiality"
        optionLabel="name"
        placeholder="Confidentiality"
        class="dropdown-width"
      />
      <div class="expiration-date">
        <p-calendar
          [(ngModel)]="sidePanel.selectedExpirationDate"
          [minDate]="minDate"
          [maxDate]="maximumDate"
          dateFormat="dd / mm / yy"
          [readonlyInput]="true"
          inputId="expiration_date"
          placeholder="Expiration date (optional)"
        />
      </div>
      <p-button
        label="Apply & Get Verified label"
        [disabled]="!sidePanel.selectedApprovalConfidentiality.name"
        (onClick)="handleWorkflowAction('approved')"
      />
    </div>
  </app-loading-component>
</p-dialog>

<!-- FLAG DIALOG -->
<p-dialog
  header="Why are you flagging this?"
  [modal]="true"
  [(visible)]="sidePanel.openDialogs.flag"
  [draggable]="false"
  position="bottom"
  [style]="{ width: '100%' }"
>
  <textarea
    rows="5"
    pInputTextarea
    [(ngModel)]="sidePanel.reasonToFlag"
    class="description-textarea"
  >
  </textarea>
  <div class="description-actions">
    <p-button
      label="Cancel"
      severity="secondary"
      (mousedown)="toggleDialog('flag')"
    />
    <p-button
      label="Save"
      (mousedown)="flagDocument()"
      [disabled]="!sidePanel.reasonToFlag"
    />
  </div>
</p-dialog>

<!-- VERSIONING DIALOG -->
<p-dialog
  header="Add version"
  [modal]="true"
  [(visible)]="sidePanel.openDialogs.versioning"
  [draggable]="false"
  position="bottom"
  [style]="{ width: '100%' }"
  class="dropdown-scroll"
>
  @if (sidePanel.creatingDraft) {
    <div class="center-load">
      <p-progressSpinner
        [style]="{ width: '128px', height: '128px' }"
      ></p-progressSpinner>
    </div>
  } @else {
    <div class="create-new-version">
      <p-dropdown
        [options]="sidePanel.documentVersioningTypes"
        [(ngModel)]="sidePanel.selectedDocumentVersioningType"
        optionLabel="name"
        placeholder="Document type"
        class="dropdown-width"
      />
      @if (
        sidePanel.selectedDocumentVersioningType.name ===
        sidePanel.documentVersioningTypes[1].name
      ) {
        <input
          pInputText
          type="text"
          [(ngModel)]="sidePanel.selectedDocumentVersioningDriveUrl"
          placeholder="Paste G-Drive file's URL here"
          class="dropdown-width"
        />
      }
      <p-button
        label="Go to my new version draft"
        (click)="newVersion()"
        [disabled]="
          !sidePanel.selectedDocumentVersioningDriveUrl &&
          sidePanel.selectedDocumentVersioningType.name ===
            sidePanel.documentVersioningTypes[1].name
        "
      />
    </div>
  }
</p-dialog>

<!-- LOCKED FILE -->
@if (sidePanel.fileLocked) {
  @if (sidePanel.userIsReaderOrCommentator) {
    <div class="bottom-action bottom-central">
      <span>This document is locked</span>
    </div>
  } @else {
    <div class="bottom-action">
      <span>This document is locked</span>
      <span class="white-link" (click)="unlockFile()">Unlock to edit</span>
    </div>
  }
}
