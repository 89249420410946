import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { EventManager } from 'app/services/events/events.service';
import { BulkService } from 'app/services/bulk/bulk.service';
import { TagService } from 'app/services/tag/tag.service';
import { TagDto } from 'app/types/tag.types';
import {
  CONFIDENTIALITIES,
  DOC_TYPES,
  LANGUAGES,
  UnprocessedEntityReason,
} from 'app/types/constants';
import { DialogState, DialogType } from 'app/types/dialogTypes';
import { FileEntity, PrimeFileNode } from 'app/types/documentTypes';
import {
  Observable,
  catchError,
  forkJoin,
  lastValueFrom,
  map,
  of,
  switchMap,
  takeUntil,
  ReplaySubject,
} from 'rxjs';
import {
  BulkResponse,
  UnprocessedEntity,
  UpdateFilePropertiesOptions,
} from 'app/types/bulk.types';
import { ToastService } from 'app/services/toast/toast.service';

const UNPROCESSED_ENTITY_REASON_TO_STRING: {
  [key: string]: string;
} = {
  [UnprocessedEntityReason.NOT_ENOUGH_PERMISSIONS]:
    "Overlayer doesn't have enough permissions to launch a verification",
  [UnprocessedEntityReason.NOT_FOUND]:
    'Shared drive manager not found, preventing Overlayer to launch a verification',
  [UnprocessedEntityReason.VERIFICATION_ALREADY_REQUESTED]:
    'Verification has already been requested',
  [UnprocessedEntityReason.ALREADY_VERIFIED]: 'Doc already verified',
};

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit, OnChanges {
  @Input() selectedFiles: Array<PrimeFileNode> = new Array<PrimeFileNode>();
  @Input() verification: boolean = false;
  @Input() AI: boolean = false;
  @Input() properties: boolean = false;
  @Input() request: boolean = false;
  @Input() reject: boolean = false;
  @Input() isDriveScanned: boolean = true;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  docTypes: { name: string }[] = DOC_TYPES.map((c) => ({ name: c }));
  languages: { name: string }[] = LANGUAGES.map((c) => ({ name: c }));
  confidentialities: { name: string }[] = CONFIDENTIALITIES.map((c) => ({
    name: c,
  }));

  dialogModalStates: DialogState = {
    rejectDocuments: {
      opened: false,
    },
    verifyDocuments: {
      opened: false,
    },
    requestVerification: {
      opened: false,
    },
    updateProperties: {
      opened: false,
    },
    disableEditProperties: {
      opened: false,
    },
    requestVerificationErrors: {
      opened: false,
    },
  };

  askToBeEditor: boolean = false;
  idsFilesSelected: string[] = [];

  selectedTags: Array<TagDto> = new Array<TagDto>();
  selectedConfidentiality: { name: string } = { name: '' };
  suggestedTags: Array<TagDto> = new Array<TagDto>();
  tags: Array<TagDto> = new Array<TagDto>();
  selectedDocType?: { name: string };
  selectedLanguage?: { name: string };

  reasonToBeEditor: string = '';
  entitiesNotUpdated: UnprocessedEntity<PrimeFileNode>[] = [];
  unrequestedFiles: UnprocessedEntity<PrimeFileNode>[] = [];

  selectedExpirationDate: Date | null = null;
  minDate: Date = new Date();
  maximumDate: Date = new Date(
    new Date().setFullYear(new Date().getFullYear() + 10),
  );

  constructor(
    private tagService: TagService,
    private toastService: ToastService,
    private bulkService: BulkService,
  ) {}

  ngOnChanges(): void {
    this.idsFilesSelected = this.selectedFiles.map((objet) => objet.id);
  }

  ngOnInit(): void {
    const tagsDataPromise = lastValueFrom(this.tagService.get());
    tagsDataPromise.then((tagsData) => {
      this.tags = tagsData?.tags;
    });
    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  rejectDocuments() {
    this.bulkService
      .rejectFileWorkflows(this.idsFilesSelected)
      .subscribe((response) => {
        this.toggleDialogState('rejectDocuments', false);
        if (response.failed?.length === 0) {
          this.toastService.success({
            detail:
              'Verification rejected: ' +
              this.selectedFiles.length +
              ' files have been rejected successfully',
          });
        }
        EventManager.emit('tableRefresh');
      });
  }

  verifyDocuments() {
    const expirationDate = new Date(this.selectedExpirationDate!).toISOString();

    if (this.selectedConfidentiality.name !== '') {
      this.bulkService
        .approveFileWorkflows(
          this.idsFilesSelected,
          this.selectedConfidentiality?.name.toLocaleLowerCase(),
          this.selectedExpirationDate ? expirationDate : null,
        )
        .subscribe((response) => {
          this.toggleDialogState('verifyDocuments', false);
          if (response.failed?.length === 0) {
            this.toastService.success({
              detail:
                'Verification approved: ' +
                this.selectedFiles.length +
                ' files have been approved successfully',
            });
          }
          EventManager.emit('tableRefresh');
        });
    } else {
      this.toastService.error({
        summary: 'Please select a confidentiality level',
      });
    }
  }

  applyProperties(): void {
    // Call the utility function to build params
    const fileProperties = this.buildFilePropertiesOptions();

    // Apply confidentiality
    if (
      this.selectedConfidentiality &&
      this.selectedConfidentiality.name !== ''
    ) {
      this.bulkService
        .updateFilesConfidentiality({
          file_ids: fileProperties.file_ids,
          confidentiality: this.selectedConfidentiality.name.toLowerCase(),
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.toastService.success({
              summary: `Confidentiality have been applied successfully on ${this.selectedFiles.length} files`,
            });
          },
          error: () => {
            this.toastService.error({
              summary: 'Could not apply confidentiality',
            });
          },
        });
    }
    // Apply generic properties
    this.bulkService
      .updateFilesProperties(fileProperties)
      .pipe(
        switchMap((response) => {
          return this.applyTags().pipe(
            map((tagsIds) => ({ response, tagsIds })),
          );
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: ({ response, tagsIds }) => {
          this.selectedConfidentiality =
            this.selectedDocType =
            this.selectedLanguage =
              { name: '' };
          this.selectedTags = [];
          EventManager.emit('tableRefreshContent', {
            datamart: response,
            tags: tagsIds,
          });

          this.toggleDialogState('updateProperties', false);

          if (response.failed?.length === 0) {
            this.toastService.success({
              detail: `Properties have been applied successfully on ${this.selectedFiles.length} files`,
            });
          } else {
            const failedEntities: {
              [id: string]: UnprocessedEntity<PrimeFileNode>;
            } = {};
            response.failed?.forEach((failed) => {
              if (!failed.reason) return;

              failedEntities[failed.id] = {
                id: this.selectedFiles.find((obj) => obj.id === failed.id)!,
                reason: UNPROCESSED_ENTITY_REASON_TO_STRING[failed.reason],
              };
            });

            this.entitiesNotUpdated = Object.values(failedEntities);
            this.dialogModalStates.disableEditProperties.opened = true;
          }
        },
        error: () => {
          this.toastService.error({ summary: 'Could not update properties' });
        },
      });
  }

  applyTags(): Observable<BulkResponse<FileEntity, string> | null> {
    if (this.selectedTags.length === 0) {
      return of(null); // or any default behavior you need
    }
    const tagsId: number[] = [];
    // Map each selected tag to an observable
    const observables = this.selectedTags.map((tag: TagDto) => {
      if (tag.id !== -1) {
        tagsId.push(tag.id);
        return of(tag.id);
      } else {
        if (tag.label !== '') {
          return this.tagService.create(tag.label).pipe(
            map((response) => {
              tagsId.push(response.tag.id);
              return response.tag.id;
            }),
          );
        } else {
          return of(null);
        }
      }
    });

    // ForkJoin waits for all observables to complete
    return forkJoin(observables).pipe(
      switchMap(() => {
        // Update files with collected tag ids
        return this.bulkService.updateFileTags({
          file_ids: this.idsFilesSelected,
          tag_ids: tagsId,
        });
      }),
      map((response) => {
        return {
          processed: response.processed,
          failed: response.failed,
        };
      }),
      catchError(() => {
        this.toastService.error({ summary: 'Could not update tags' });
        return of({ processed: [], failed: [] }); // Handle errors by returning empty structure
      }),
    );
  }

  searchTags(event: { query: string }) {
    this.suggestedTags = [];

    if (event.query === '') {
      return this.suggestedTags;
    }

    const tagExists = this.tags.filter(
      (tag) => tag.label.toLowerCase() === event.query.toLocaleLowerCase(),
    );

    this.tags.forEach((tag: TagDto) => {
      if (tag.label.toLowerCase().includes(event.query.toLowerCase()))
        this.suggestedTags.push(tag);
    });

    if (tagExists.length === 0) {
      // Add the tag to the beginning of the list if it doesn't exist without deleting the other tags
      this.suggestedTags = [
        { id: -1, label: event.query },
        ...this.suggestedTags,
      ];
    }
    return this.suggestedTags;
  }

  closeReasonToBeEditorDialog() {
    this.askToBeEditor = false;
  }

  sendReasonToBeEditor() {
    this.closeReasonToBeEditorDialog();
    this.reasonToBeEditor = '';
    this.toastService.success({
      detail:
        'Properties have been applied successfully on ' +
        this.selectedFiles.length +
        ' files',
    });
  }

  requestEditorAccess() {
    this.dialogModalStates.disableEditProperties.opened = false;

    //this.askToBeEditor = true;
  }

  requestVerification() {
    this.bulkService
      .requestFileVerification(this.idsFilesSelected)
      .subscribe((response) => {
        this.toggleDialogState('requestVerification', false);
        if (response.failed?.length === 0) {
          this.toastService.success({
            detail:
              'Verification request: Verification has been requested on ' +
              this.selectedFiles.length +
              '  files successfully.',
          });
        } else {
          const failedEntities: {
            [id: string]: UnprocessedEntity<PrimeFileNode>;
          } = {};
          response.failed?.forEach((failed) => {
            if (!failed.reason) return;

            failedEntities[failed.id] = {
              id: this.selectedFiles.find((obj) => obj.id === failed.id)!,
              reason: UNPROCESSED_ENTITY_REASON_TO_STRING[failed.reason],
            };
          });

          this.unrequestedFiles = Object.values(failedEntities);
          this.dialogModalStates.requestVerificationErrors.opened = true;
        }
      });
  }

  toggleDialogState(dialogType: DialogType, state: boolean) {
    this.dialogModalStates[dialogType].opened = state;
  }

  buildFilePropertiesOptions(): UpdateFilePropertiesOptions {
    const params: UpdateFilePropertiesOptions = {
      file_ids: this.idsFilesSelected,
    };

    if (this.selectedDocType && this.selectedDocType.name !== '') {
      params.document_type = this.selectedDocType.name;
    }

    if (this.selectedLanguage && this.selectedLanguage.name !== '') {
      params.language = this.selectedLanguage.name;
    }

    return params;
  }

  dismissVerificationErrorsModal() {
    this.unrequestedFiles = [];
    this.toggleDialogState('requestVerificationErrors', false);
  }
}
