import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ModifyTagOptions,
  TagResponse,
  TagsResponse,
} from 'app/types/tag.types';
import { TrinityService } from 'app/services/trinity/trinity.service';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  private readonly endpoint: string = '/tag';
  constructor(private trinityService: TrinityService) {}

  get(): Observable<TagsResponse> {
    const res = this.trinityService.get<TagsResponse>(`${this.endpoint}`, {
      authorized: true,
    });

    return res;
  }

  create(label: string): Observable<TagResponse> {
    const res = this.trinityService.post<TagResponse>(`${this.endpoint}`, {
      body: { label },
      authorized: true,
    });

    return res;
  }

  modify(options: ModifyTagOptions): Observable<TagResponse> {
    const res = this.trinityService.put<TagResponse>(
      `${this.endpoint}/${options.id}`,
      {
        body: {
          label: options.label,
        },
        authorized: true,
      },
    );

    return res;
  }
}
