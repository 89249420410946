export interface Domain {
  /** Domain's ID. */
  created_at: Date;

  /** Domain's creation date. */
  id: number;

  /** Domain's name. */
  name: string;

  /** Domain's last update date. */
  updated_at: Date;

  /** Is this domain the primary domain?. */
  is_primary: boolean;

  /** Domain's parent domain. */
  parent?: Domain;
}

export interface AuthorizeUri {
  authorize_url: string;
}

export interface JwtTokenPair {
  /** Access token. */
  access_token: string;

  /** Access token expiry date. */
  expiry_date: number;

  /** Refresh token. */
  refresh_token: string;

  /** Token type. */
  token_type: string;

  /** ID token. */
  id_token?: string;

  /** Scope. */
  scope?: string;
}

export interface User {
  /** User's ID. */
  id: number;

  /** User's creation date. */
  created_at: Date;

  /** User's email address. */
  primary_email: string;

  /** Users's emails list */
  emails: string[];

  /** User's name. */
  name: string;

  /** User's picture URI. */
  picture_uri: string;

  /** User's role. */
  roles: Role[];

  /** User's last update date. */
  updated_at: Date;

  /** User's domain. */
  domain: Domain;
}

export enum Role {
  ADMIN = 'admin',
  CLIENT = 'client',
  DOMAIN_ADMIN = 'domain_admin',
  DEDICATED_ACCOUNT = 'dedicated_account',
}
